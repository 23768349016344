body, html{
    margin: 0;
    padding: 0;
    box-model: border-box;
}
a, a:active, a:visited{
    color: black;
}
@keyframes spinner{
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}


